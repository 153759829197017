<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12 px-0">
                <div class="card">
                    <div class="card-body">
                         <div class="row">
                             <div class="col-lg-6">
                                 <h4 class="header-title font-weight-bold"> List of Recipes</h4>
                             </div>
                             <div class="col-lg-6">
                                 <div class="float-sm-right">
                                     <router-link
                                             to="/recipe/add"
                                             class="btn btn-danger mb-2"
                                     ><i class="mdi mdi-plus-circle mr-1"></i> Add
                                         Recipe</router-link
                                     >
                                 </div>
                             </div>
                         </div>
                        <div>
                            <lottie-loader :loading="showLoader" />
                        </div>
                        <p class="text-muted font-13 mb-4"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" id="table">
                            <b-table
                                    table-class="table table-centered w-100"
                                    thead-tr-class="bg-light"
                                    :items="listOfRecipes"
                                    :fields="tableFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(image)="data">
                                    <img
                                            v-if="data.item.image"
                                            :src="data.item.image"
                                            alt=""
                                            class="rounded mr-3"
                                            height="48"
                                    />
                                    <div
                                            v-if="!data.item.image"
                                            class="avatar-xs d-inline-block mr-2"
                                    >

                                    </div>

                                </template>

                                <template #cell(dateCreated)="data">
                                    <span class="font-12 font-weight-bold text-dark">{{ data.item.dateCreated | moment("MMMM Do YYYY") }}</span>
                                </template>
                                <template #cell(name)="data">
                                    <span class="font-14 font-weight-bold text-dark">   {{ data.item.name}}</span>
                                </template>
                                <template #cell(activeTimeInMins)="data">
                                    <span class="badge badge-outline-info p-1">   {{ data.item.activeTimeInMins}}</span>
                                </template>
                                <template #cell(servingTimeInMins)="data">
                                    <span class="badge badge-outline-secondary p-1">   {{ data.item.servingTimeInMins}}</span>
                                </template>
                                <template #cell(calories)="data">
                                    <span class="badge badge-outline-danger p-1">   {{ data.item.calories}}</span>
                                </template>
                                <template #cell(actions)="data">
                                    <router-link class="btn btn-primary mr-2" style="cursor: pointer" :to="'/recipe/'+data.item.id"> <i class="mdi mdi-eye mx-1"></i>View</router-link>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import SweetAlertWrapper from "../../../../sweetAlert";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import LottieLoader from "../../../components/LottieLoader";
    export default {
        name: "ManageRecipes",
        components : {
            Layout,
            PageHeader,
            LottieLoader
        },
        data() {
            return {
                showLoader : false,
                listOfRecipes :[],
                recipe : {
                    id :'',
                    name :'',
                    categoryId:0,
                    activeTimeInMins :0,
                    servingTimeInMins :0,
                    colories :0,
                    ingredients :0,
                    instructions :''
                },
                title: "Manage recipe",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "recipe",
                        active: true,
                    },
                ],

                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",
                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"Name",
                        key: "name",
                        sortable: true,
                    },
                    {
                        label:"Product image",
                        key: "image",
                        sortable: true,
                    },
                    {
                        label :"Active Time in Minute(s)",
                        key: "activeTimeInMins",
                        sortable: true,
                    },
                    {
                        label :"Serving Time in Minute(s)",
                        key: "servingTimeInMins",
                        sortable: true,
                    },
                    {
                        label :"Calories",
                        key: "calories",
                        sortable: true,
                    },
                    {
                        label:"Date createed",
                        key: "dateCreated",
                        sortable: true,
                    },
                    {
                        key: "actions",
                        sortable: true,
                    },
                ],
            }
        },
        methods : {
            GetListsOfRecipes() {
                this.showLoader = true;
                BaseUrl.AxiosBearer.get(`store/recipes/get-recipes?page=1&pageSize=1000000`).then((resp) => {
                    this.showLoader = false
                    if(resp) {
                        this.listOfRecipes = []
                        resp.data.payload.forEach((data) => {
                            this.listOfRecipes.push({
                                id : data.id,
                                name : data?.recipeName,
                                image : data?.mainImage?.imageUrl ,//data.product,
                                activeTimeInMins : data?.activeTimeInMins,
                                servingTimeInMins : data?.servingTimeInMins,
                                dateCreated : data.createdAt,
                                calories : data?.calories
                            })
                        })
                    }

                }).catch((error) => {
                    this.$bvToast.toast(error, ToastConfigAlert)
                })
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        created() {
            this.sweetAlert = new SweetAlertWrapper()
            this.GetListsOfRecipes()
        },
        computed: {
            rows() {
                return this.listOfRecipes.length;
            },
        },
    }
</script>

<style scoped>

</style>